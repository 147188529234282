import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";


const firebaseConfig = {
  apiKey: "AIzaSyBRhs1dw8e-kyDP2xsplVaburBhKkVoatM",
  authDomain: "doodychatdev.firebaseapp.com",
  databaseURL: "https://doodychatdev-default-rtdb.firebaseio.com",
  projectId: "doodychatdev",
  storageBucket: "doodychatdev.appspot.com",
  messagingSenderId: "430862363432",
  appId: "1:430862363432:web:5121c21dfb8cded62a3e1d"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const firestore = getFirestore(app);

const database = getDatabase(app);

// auth.settings.appVerificationDisabledForTesting = true;

export const firebaseApp = {
  firebaseConfig,
  auth,
  app,
  firestore,
  database
};