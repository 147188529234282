import React from 'react';
import { useStyles } from './Loader.style.js';
import './Loader.css';
import logoWhite from './../../../assets/images/logoWhite.png'

const Loader = () => {
    const classes = useStyles();

    return (
        <div className={`loading-overlay is-active ${classes.Loader}`}>
            {/* <span id="loader"></span> */}
            <div class="loading-pulse">
                <img src={logoWhite} />
            </div>
        </div>
    )
}

Loader.prototype = {};

Loader.defaultProps = {};

export default Loader