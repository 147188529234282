import React from 'react';
import './App.css';
import AppRoute from './routes';
import 'react-phone-input-2/lib/style.css'

const App = () => {
  return (
    <div className='app-root'>
      <AppRoute />
    </div>
  );
}

export default App;
