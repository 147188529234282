import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { firebaseApp } from '../firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const PrivateRoute = ({ 
    redirectPath = '/signin',
    children,
}) => {
    const { auth } = firebaseApp

    const [user] = useAuthState(auth);

    if (!user) {
        return <Navigate to={redirectPath} replace />;
    }

    return children;
}


export default PrivateRoute;