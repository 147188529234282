import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    user: {
        email: '',
        username: '',
        mobile: '',
        dialCode: '',
        password: '',
        formattedMobile: '',
        mobileWithDialCode: ''
    },
    confirmation: null,
    recaptchaWidgetId: null,
    appVerifier: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateAuth: (state, action) => {
            state.user = action.payload;
        },
        updateConfirmation: (state, action) => {
            state.confirmation = action.payload;
        },
        updateRecaptchWidgetId: (state, action) => {
            state.recaptchaWidgetId = action.payload;
        },
        updateAppVerifier: (state, action) => {
            state.appVerifier = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { updateAuth, updateConfirmation, updateAppVerifier, updateRecaptchWidgetId } = authSlice.actions

export default authSlice.reducer