import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Loader from '../app/components/Common/Loader';

const Splash = lazy(() => import('./../app/components/Splash'));
const Onboarding = lazy(() => import('./../app/components/Onboarding'));
const Terms = lazy(() => import('./../app/components/Terms'));
const Signin = lazy(() => import('./../app/components/Auth/Signin'));
const Signup = lazy(() => import('./../app/components/Auth/Signup'));
const ForgotPassword = lazy(() => import('./../app/components/Auth/ForgotPassword'));
const Otp = lazy(() => import('./../app/components/Auth/Otp'));
const ChatDashboard = lazy(() => import('./../app/components/Chat/ChatDashboard'));
const BootstrapDashboard = lazy(() => import('./../app/components/BootstrapDashboard'));
const Dashboard = lazy(() => import('./../app/components/ChatApp'));

const Approutes = () => {
  const [showSplash, setShowSplash] = useState(true);
  const [showOnboarding, setShowOnboarding] = useState(true);
  const [showTerms, setShowTerms] = useState(true);

  useEffect(() => {
    // Hide the splash screen after 5 seconds
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 2000);

    // Clean up the timer
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Check if the user has already completed onboarding
    const hasCompletedOnboarding = localStorage.getItem('hasCompletedOnboarding');
    if (hasCompletedOnboarding && hasCompletedOnboarding !== null) {
      setShowOnboarding(false);
    }
    // Check if the user has already completed Terms
    const hasCompletedTerms = localStorage.getItem('hasTermsAndCondition');
    if (hasCompletedTerms && hasCompletedTerms !== null) {
      setShowTerms(false);
    }
  }, []);

  const handleOnboardingComplete = () => {
    // Mark onboarding as completed
    localStorage.setItem('hasCompletedOnboarding', 'true');
    setShowOnboarding(false);
  };

  const handleTermsComplete = () => {
    // Mark Terms as completed
    localStorage.setItem('hasTermsAndCondition', 'true');
    setShowTerms(false);
  };

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        {showSplash ?
          <Splash /> :
          <Routes>
            {showOnboarding && (<Route path="/" exact name="Onboarding" element={<PublicRoute><Onboarding onComplete={handleOnboardingComplete} /></PublicRoute>} />)}

            {(!showOnboarding && showTerms) && <Route path="/" element={<PublicRoute><Navigate to="/terms" replace /></PublicRoute>} />}

            {showTerms && (<Route path="/terms" exact name="Terms" element={<PublicRoute><Terms onComplete={handleTermsComplete} /></PublicRoute>} />)}

            {(!showOnboarding && !showTerms) && (
              <>
                <Route exact path="/signin" name="Signin" element={<PublicRoute><Signin /></PublicRoute>} />
                <Route exact path="/signup" name="Signup" element={<PublicRoute><Signup /></PublicRoute>} />
                <Route exact path="/forgot-password" name="ForgotPassword" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
                <Route exact path="/otp" name="Otp" element={<PublicRoute><Otp /></PublicRoute>} />

                <Route exact path="/bootstrap" element={<BootstrapDashboard />} />
                <Route exact path="/old" element={<ChatDashboard />} />
                <Route exact path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
              </>
            )}
          </Routes>
        }

      </Suspense>
    </Router>
  );
}

export default Approutes;
